import React, { useContext } from "react"
import styled from "styled-components"
import { myContext } from "../ThemeContext"
import { Col, Row } from "react-bootstrap"

function IconBoxList(props) {
  const theme = useContext(myContext)

  return (
    <IconsList theme={theme} noBorder={props.noBorder}>
      <Row>
        <Col>
          <h3>{props.title}</h3>
        </Col>
      </Row>
      <Row className="mt-20">
        {props.items.map(item => (
          <Col md={props.boxSizeLarge ? "6" : "4"}>
            <IconItem>
              <div className="icon">{item.icon}</div>
              <div className="content">
                <h5>{item.title}</h5>
                <p>{item.desc}</p>
              </div>
            </IconItem>
          </Col>
        ))}
      </Row>
    </IconsList>
  )
}

const IconsList = styled.div`
  margin: 1rem 0;
  h3 {
    text-align: center;
  }
  .icon {
    margin-bottom: 0;
    width: 58px;
    height: 58px;
    min-width: 58px;
    min-height: 58px;
    line-height: 58px;
    display: inline-block;
    border: ${props =>
      props.noBorder ? "" : "1px solid " + props.theme.primaryBlue};
    position: relative;
    transition: all 0.4s ease-in-out;
    svg {
      font-size: 45px;
      width: 45px;
      max-height: 45px;
      color: ${props => props.theme.primaryBlue};
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
`
const IconItem = styled.div`
  margin: 1rem 0;
  display: flex;
  h5,
  p {
    padding: 0 1.5rem;
    margin-bottom: 0;
  }
  .content {
  }
`

export default IconBoxList

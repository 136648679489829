import React, { useContext } from "react"
import Layout from "../../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBoxes,
  faCheckCircle,
  faCoins,
  faDesktop,
  faExchangeAlt,
  faLaptopMedical,
  faNetworkWired,
  faRecycle,
  faServer,
  faShieldAlt,
  faUserEdit,
  faVolumeUp,
  faWifi,
} from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import PartnerCarousel from "../../components/PartnerCarousel"
import PeaceOfMindGuarantee from "../../components/PeaceOfMindGuarantee"
import SectionTitle from "../../components/SectionTitle"
import Services from "../../components/Services"
import { Banner } from "../../components/Banner"
import { IconCardsStyle2 } from "../../components/business/IconCards"
import { myContext } from "../../ThemeContext"
import BusinessOffers from "../../components/business/BusinessOffers"
import SEO from "../../components/seo"
import IconBoxList from "../../components/BoxList"

export default function Support() {
  const theme = useContext(myContext)
  const data = useStaticQuery(graphql`
    query businessSupport {
      ITNetworks: file(
        relativePath: { eq: "services/AdobeStock_119201395.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Software: file(
        relativePath: { eq: "services/AdobeStock_317550082.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Maintenance: file(
        relativePath: { eq: "services/AdobeStock_308707407.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      BannerImage: file(
        relativePath: { eq: "banner/AdobeStock_297400808.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const services = [
    {
      title: "IT Networks",
      description: "Servers, Storage, Cabling, Switches, Web Filtering",
      image: data.ITNetworks.childImageSharp.fixed,
    },
    {
      title: "Software",
      description:
        "Mobile Device Management, Microsoft, Operating Systems, Adobe, User Monitoring",
      image: data.Software.childImageSharp.fixed,
    },
    {
      title: "Maintenance",
      description:
        "Extended Warranties, Telephone Support, Remote Support, Onsite Support, Installation",
      image: data.Maintenance.childImageSharp.fixed,
    },
  ]

  const MaintenanceAgreement = styled.div`
    #MaintenanceAgreement {
      display: block;
      position: relative;
      top: -130px;
      visibility: hidden;
    }
    margin: 0rem 0 2rem 0;
    h3,
    p {
      text-align: center;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 1rem 0;
      padding-left: 0;
      li {
        font-size: 16px;
        padding-left: 25px;
        list-style: none;
        position: relative;
        padding-bottom: 10px;
        svg {
          position: absolute;
          left: 0px;
          top: 5px;
          color: #27ae60;
        }
      }
    }
  `

  const FlexboxWrapper = styled.div`
    display: flex;
    justify-content: center;
    li {
      color: #6e6e6e;
    }
  `

  const TextAlignCenter = styled.div`
    text-align: center;
  `

  const WarrantySection = styled.div`
    background: ${theme.grey};
    #Warranty {
      display: block;
      position: relative;
      top: -130px;
      visibility: hidden;
    }
    h3 {
      text-align: center;
    }
  `

  const IconsWithText = styled.div`
    #SupportSolutions {
      display: block;
      position: relative;
      top: -130px;
      visibility: hidden;
    }
    background: ${theme.grey};
    margin: 2rem 0 4rem 0;
    padding: 2rem 0;
  `

  return (
    <Layout headerType="business">
      <SEO title="Compulease · Support Business" />
      <BusinessOffers />
      <Container>
        <Row>
          <Col>
            <SectionTitle
              title="Support for your Business"
              description={
                <TextAlignCenter>
                  <h3>Personal Customer Service</h3>
                  <p>
                    Supplying the “right equipment at the right price” is only
                    part of the service available to our business clients, and
                    Compulease™ prides itself in being able to deliver a
                    complete solution including vital ongoing support to provide
                    you with “complete peace of mind”.
                  </p>
                  <p>
                    The level of support depends on your individual requirements
                    and can include a combination of the following support
                    services
                  </p>
                </TextAlignCenter>
              }
            />
          </Col>
        </Row>
      </Container>
      <IconsWithText>
        <a href="/" id="SupportSolutions">
          anchor
        </a>
        <Container>
          <IconBoxList
            title="We provide flexible support based on your individual requirements"
            items={[
              {
                title: "Install to desk & imaging",
                description: "",
                icon: <FontAwesomeIcon icon={faDesktop} />,
              },
              {
                title: "Data Transfer",
                description: "",
                icon: <FontAwesomeIcon icon={faExchangeAlt} />,
              },
              {
                title: "Server Installation & Configuration",
                description: "",
                icon: <FontAwesomeIcon icon={faServer} />,
              },
              {
                title: "Storage Solutions",
                description: "",
                icon: <FontAwesomeIcon icon={faBoxes} />,
              },
              {
                title: "Network Cabling & Electrical Work",
                description: "",
                icon: <FontAwesomeIcon icon={faNetworkWired} />,
              },
              {
                title: "Audiovisual Installation",
                description: "",
                icon: <FontAwesomeIcon icon={faVolumeUp} />,
              },
              {
                title: "Bespoke Furniture Install",
                description: "",
                icon: <FontAwesomeIcon icon={faUserEdit} />,
              },
              {
                title: "No capital outlay",
                description: "",
                icon: <FontAwesomeIcon icon={faCoins} />,
              },
              {
                title: "Wireless Installation",
                description: "",
                icon: <FontAwesomeIcon icon={faWifi} />,
              },
            ]}
          />
        </Container>
      </IconsWithText>
      <MaintenanceAgreement>
        <a href="/" id="MaintenanceAgreement">
          anchor
        </a>
        <Container>
          <Row>
            <Col>
              <h3>Maintenance Agreement</h3>
              <p>
                Compulease™ can provide complete maintenance packages which
                provide you with varying levels of support dependant on your
                situation. The Elite maintenance package provides dedicated
                support and is effectively like having your own IT Manager and
                includes
              </p>
              <Row>
                <Col>
                  <FlexboxWrapper>
                    <ul>
                      {[
                        "Telephone technical support 9am until 5pm Monday to Friday",
                        "Remote access assistance",
                        "On-site next business day support if issue not resolved by telephone or remote access",
                        "Hardware cover including parts",
                        "Software support for Operating System, Microsoft Server Applications and Microsoft Office",
                        "Loan machine in the event a system has to be taken off site for repair",
                        "Network administration assistance",
                        "Proactive server monitoring to identify/remedy issues before they become a problem",
                      ].map(item => (
                        <li>
                          <FontAwesomeIcon icon={faCheckCircle} />
                          {item}
                        </li>
                      ))}
                    </ul>
                  </FlexboxWrapper>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </MaintenanceAgreement>
      <WarrantySection>
        <Container>
          <a href="/" id="Warranty">
            anchor
          </a>
          <IconCardsStyle2
            items={[
              {
                title: "Warranty",
                description: (
                  <>
                    <p>
                      All hardware is covered by manufacturer’s warranties and
                      in most cases these can be enhanced even further.
                    </p>
                    <p>
                      Most PCs and laptops are supplied as standard with a 3
                      year next business day on-site warranty to reduce any
                      potential downtime to a minimum.
                    </p>
                  </>
                ),
                icon: <FontAwesomeIcon icon={faShieldAlt} />,
              },
              {
                title: "Equipment Protection Cover",
                description: (
                  <>
                    <p>
                      Compulease™ can provide low cost comprehensive IT
                      insurance to provide additional peace of mind.
                    </p>
                    <p>Cover for Theft, Accidental Damage, Fire & Flood</p>
                    <p>Little or no excess</p>
                    <p>Worldwide cover with no travel limitations</p>
                    <p>No specialist security requirements</p>
                    <p>Rapid claims response</p>
                  </>
                ),
                icon: <FontAwesomeIcon icon={faLaptopMedical} />,
              },

              {
                title: "Asset Disposal",
                description: (
                  <p>
                    IT Asset Disposal enables you to discard old IT equipment
                    rapidly and legally. We ensure complete compliance with
                    current and future technology disposal regulations. We also
                    help you maximise your financial return on discarded
                    equipment. All fully-certifiable and ISO-accredited, ISC
                    solutions range from environmental disposal to asset-managed
                    remarketing and secure data purging. We offer comprehensive
                    advice and management on all available options and provide
                    full licensing support.
                  </p>
                ),
                icon: <FontAwesomeIcon icon={faRecycle} />,
              },
            ]}
          />
        </Container>
      </WarrantySection>
      <Banner
        image={data.BannerImage.childImageSharp.fluid}
        title="Speak to our friendly customer service team for all your business computer leasing  finance needs"
        link={{
          text: "Get in Touch",
          to: "/business/ContactUs/",
        }}
      />
      <Services title="Solutions we provide" services={services} />
      <Container>
        <section id="PeaceOfMindGuarantee">
          <PeaceOfMindGuarantee />
        </section>
        <PartnerCarousel type="business" />
      </Container>
    </Layout>
  )
}
